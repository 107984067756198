<template>
  <div>
    <el-form :inline="true" ref="formData" :model="formData" class="demo-form-inline" style="text-align: left">
      <el-form-item >
          <el-input  placeholder="请输入内容" v-model="formData.keywords" class="input-with-select">
            <el-select class="el-select" v-model="formData.searchField" slot="prepend" placeholder="请选择">
              <el-option label="订单编号" value="1">订单编号</el-option>
              <el-option label="会员编号" value="2">会员编号</el-option>
              <el-option label="手机号" value="3">手机号</el-option>
<!--              <el-option label="身份证号" value="3">身份证号</el-option>-->
            </el-select>
          </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch('formData')">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
        v-loading="loading"
        :data="tableData"
        stripe
        style="width: 100%" max-height="600">
      <el-table-column
          prop="id"
          label="#ID" width="50"
          >
      </el-table-column>
      <el-table-column
          prop="ordersn"
          label="订单编号" width="200"
      >
      </el-table-column>
      <el-table-column
          prop="country.name"
          label="国家" width="100"
      >
      </el-table-column>
      <el-table-column
          prop="member_info"
          label="会员信息"  width="250px"
          >
        <template slot-scope="scope">
          <span v-if="scope.row.member.member_level == '1'" v-html="
          '真实姓名：'+scope.row.member.full_name+'<br />'+
          '手机号码：'+scope.row.member.mobile
"></span>
          <span v-if="scope.row.member.member_level === '3'" v-html="
          '会员编号：'+scope.row.member.uname+'<br />'+
          '真实姓名：'+scope.row.member.full_name+'<br />'+
          '手机号码：'+scope.row.member.mobile
"></span>
        </template>
      </el-table-column>
      <el-table-column
          prop="order_type_str"
          label="订单类型" width="100"
      >
      </el-table-column>
      <el-table-column
          prop="pay_amount"
          label="金额" width="100"
      >
      </el-table-column>
      <el-table-column
          prop="total_pv"
          label="PV" width="100"
      >
      </el-table-column>
      <el-table-column
          prop="pay_time"
          label="支付时间" width="200"
      >
      </el-table-column>
      <el-table-column
          prop="settlement_time"
          label="结算时间" width="200"
      >
      </el-table-column>
      <el-table-column
          prop="settle_status_str"
          label="结算状态"
      >
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="100">
        <template slot-scope="scope">
          <el-button @click="editOrder(scope.row)" type="text" size="small" v-if="myfun.checkAuthRule('adminOrderEditOrder')">编辑</el-button><br />
<!--          <el-button @click="editNet(scope.row, 1)" type="text" size="small" v-if="myfun.checkAuthRule('adminMemberEditMemberRecommend') ">改推荐人</el-button><br />-->
<!--          <el-button @click="editNet(scope.row, 2)" type="text" size="small" v-if="myfun.checkAuthRule('adminMemberEditMemberParent') && scope.row.member_level === '3'">改安置人</el-button><br />-->
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination style="margin-top: 1rem"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="formData.page"
          :page-sizes="pageSizs"
          :page-size="formData.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="formData.total">
      </el-pagination>
    </div>


    <!-- Form -->

    <el-dialog
        title="编辑订单"
        :visible.sync="editOrderdialogFormVisible"
        v-loading="loading"
    >
      <el-form :model="editOrderformData" :rules="checkEditOrderformRules" ref="editOrderformData">
        <el-form-item
            label="订单编号"
            :label-width="formLabelWidth"
            prop=""
        >
          <div style="text-align: left">{{editOrderformData.ordersn}}</div>
        </el-form-item>
        <el-form-item
            label="结算时间"
            :label-width="formLabelWidth"
            prop="settlement_time" required style="text-align: left"
        >
          <el-date-picker
              v-model="editOrderformData.settlement_time" :default-value="new Date()"
              type="datetime"
              placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item
            label="是否结算"
            :label-width="formLabelWidth" style="text-align: left"
            prop="settle_status" required v-if="false"
        >
          <el-switch :disabled="!myfun.checkAuthRule('adminMemberEditMember')"
                     class="switch custom"
                     v-model="editOrderformData.settle_status"
                     :active-value="'1'"
                     :inactive-value="'0'"
                     active-text="结算"
                     inactive-text="不结算"
          ></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editOrderdialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="doEditOrder('editOrderformData')"
        >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'OrderList',
    components: {
    },
    data() {
      return {
        test: true,
        formData: {
          page: 1,
          pagesize: 10,
          total: 0,
          keywords: '',
          searchField: '1'
        },
        tableData:[],
        loading: false,
        editOrderdialogFormVisible: false,
        editOrderformData: {
          id: '0',
          ordersn: '',
          settlement_time: '',
          settleTimeStr: '',
          settle_status: true
        },
        checkEditOrderformRules: {
        },
        formLabelWidth: '120px'
      }
    },
    methods: {
      getList(page = 0){
        this.tableData = [];
        if(page > 0){
          this.formData.page = page;
        }
        this.loading = true;
        this.myfun.request('adminOrderGetOrderList.api', this.formData, 'post').then((result) => {
          if (result.code == '1') {
            this.tableData = result.data.list;
          } else {
            this.$message({
              message: result.msg,
              type: 'warning'
            });
          }
          this.formData.total = parseInt(result.data.total);
          this.loading = false;
        });
      },
      handleSizeChange(val) {
        // console.log(`每页 ${val} 条`);
        this.formData.pagesize = val;
        this.getList();
      },
      handleCurrentChange(val) {
        // console.log(`当前页: ${val}`);
        this.formData.page = val;
        this.getList();
      },
      onSearch(){
        this.getList(1);
      },

      editOrder(row) {
        this.editOrderformData.id = row.id;
        this.editOrderformData.ordersn = row.ordersn;
        this.editOrderformData.settlement_time = row.settlement_time;
        this.editOrderformData.settle_status = row.settle_status;
        this.editOrderdialogFormVisible = true;
      },
      doEditOrder(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            //验证通过执行请求
            this.loading = true;
            this.editOrderformData.settleTimeStr = this.myfun.timestampToDatetime(this.myfun.datetimeToTimestamp(this.editOrderformData.settlement_time));
            this.myfun
              .request('adminOrderEditOrder.api', this.editOrderformData, "post")
              .then((result) => {
                if (result.code == "1") {
                  this.getList();
                  this.$message({
                    message: result.msg,
                    type: "success",
                  });
                  this.editOrderdialogFormVisible = false;
                } else {
                  this.$message({
                    message: result.msg,
                    type: "warning",
                  });
                }
                this.loading = false;
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
    },
    created() {
      this.getList(1);
    },
    mounted() {
    },
    computed:{
      pageSizs(){
        return this.$store.state.pageSizes;
      }
    }
  }
</script>

<style>
  .el-select {
    width: 110px;
  }
  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }


  /* switch按钮样式 */
  .switch.custom .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }
  /*打开时文字位置设置*/
  .switch.custom .el-switch__label--right {
    z-index: 1;
  }
  /* 调整打开时文字的显示位子 */
  .switch.custom .el-switch__label--right span{
    margin-left: 5px;
  }
  /*关闭时文字位置设置*/
  .switch.custom .el-switch__label--left {
    z-index: 1;
  }
  /* 调整关闭时文字的显示位子 */
  .switch.custom .el-switch__label--left span{
    margin-left: 20px;
  }
  /*显示文字*/
  .switch.custom .el-switch__label.is-active {
    display: block;
  }
  /* 调整按钮的宽度 */
  .switch.custom.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 70px !important;
    margin: 0;
  }
</style>
